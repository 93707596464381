import React from 'react';
import './PrimaryButton.css'

function PrimaryButton(props) {
    return (
        <button onClick={props.onClick} className="btn-primary">
            {props.content}
        </button>
    )
}

export default PrimaryButton