import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { withNamespaces } from 'react-i18next';

import acreLogoYatay from '../../res/acrelogoyatay.svg'
import './Navbar.css'

function Navbar({ t }) {
    const [showMenu, setShowMennu] = useState(false)
    const currentPath = window.location.pathname.substring(1)
    return (
        <nav className="navbar-container">
            <div className="navbar-logo-container">
                <Link to="/anasayfa">
                    <img src={acreLogoYatay} alt="Acre Group" className="navbar-logo" />
                </Link>
            </div>
            <div className="navbar-links">
                <ul>
                    <li>
                        <Link to="/anasayfa" className={currentPath === "anasayfa" ? "active-link" : null}>{t('Anasayfa')}</Link>
                    </li>
                    <li>
                        <Link to="/hakkimizda" className={currentPath === "hakkimizda" ? "active-link" : null}>{t('Hakkimizda')}</Link>
                    </li>
                    <li>
                        <Link to="/hizmetlerimiz" className={currentPath === "hizmetlerimiz" ? "active-link" : null}>{t('Hizmetlerimiz')}</Link>
                    </li>
                    <li>
                        <Link to="/projeler" className={currentPath === "projeler" ? "active-link" : null}>{t('Projeler')}</Link>
                    </li>
                    <li>
                        <Link to="/iletisim" className={currentPath === "iletisim" ? "active-link" : null}>{t('Iletisim')}</Link>
                    </li>
                </ul>
            </div>
            <div className={showMenu ? "navbar-responsive-list-container open-hmg" : "navbar-responsive-list-container"}
                onClick={showMenu ? () => setShowMennu(false) : () => setShowMennu(true)}>
                <div className="navbar-menu-btn"></div>
            </div>

            <ul className={showMenu ? "navbar-responsive-list right-list" : "navbar-responsive-list"}>
                <li><Link to='/anasayfa'>{t('Anasayfa')}</Link></li>
                <li><Link to='/hakkimizda'>{t('Hakkimizda')}</Link></li>
                <li><Link to='/hizmetlerimiz'>{t('Hizmetlerimiz')}</Link></li>
                <li><Link to='/projeler'>{t('Projeler')}</Link></li>
                <li><Link to='/iletisim'>{t('Iletisim')}</Link></li>
            </ul>

        </nav>
    )
}

export default withNamespaces()(Navbar)
