import React from 'react'
import { Helmet } from 'react-helmet'
import './Hakkimizda.css'

import { withNamespaces } from 'react-i18next';

function Hakkimizda({ t }) {
    return (
        <div className="fluid top-border hakkimizda">
            <Helmet>
                <title>{t('Title_Hakkimizda')}</title>
            </Helmet>
            <div className="fluid-container hakkimizda-top">
                <div className="hakkimizda-title">{t('Hakkimizda')}</div>
                <div className="hakkimizda-aciklama">
                    {t('Aciklama_Hakkimizda')}
                </div>
            </div>
        </div>
    )
}

export default withNamespaces()(Hakkimizda)
