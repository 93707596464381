import axios from 'axios'
import { SEND_MAIL_LOADING, SEND_MAIL_SUCCESS, SEND_MAIL_FAIL } from './Types';

export const Email = (message) => dispatch => {

    dispatch({ type: SEND_MAIL_LOADING });
    //Headers
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const msg = {
        to: message.Subject === "Kullanıcı Oluşturuldu" ? message.To : 'info@acregroup.com.tr',
        subject: message.Subject,
        text: message.Subject === "Kullanıcı Oluşturuldu" ?
            `Sayın ziyaretçimiz kullanıcı kaydınız gerçekleşmiştir. Aşağıdaki bilgiler ile https://www.acregroup.com.tr/login bağlantısı üzerinden giriş yapabilirsiniz. Kullanıcı Adı: ${message.To}, Şifre: ${message.Sifre}`
            :
            `${message.Adiniz} isimli ziyaretçi ${message.Subject} gerçekleştirmiştir. Email: ${message.Email}, Şirket: ${message.SirketAdi}, Telefon: ${message.Telefon}, Talep: ${message.Talep}`,
        html: message.Subject === "Kullanıcı Oluşturuldu" ?
            `<strong>Sayın ziyaretçimiz kullanıcı kaydınız gerçekleşmiştir. Aşağıdaki bilgiler ile https://www.acregroup.com.tr/login bağlantısı üzerinden giriş yapabilirsiniz.<br/> Kullanıcı Adı: ${message.To},<br /> Şifre: ${message.Sifre}</strong>`
            :
            `<strong>${message.Adiniz} isimli ziyaretçi ${message.Subject} gerçekleştirmiştir.<br/> Email: ${message.Email},<br /> Şirket: ${message.SirketAdi},<br/> Telefon: ${message.Telefon},<br /> Talep: ${message.Talep}</strong>`,
    };

    //Request Body
    const body = JSON.stringify(msg)
    
    axios
        .post('https://us-central1-acreweb.cloudfunctions.net/sendEmail', body, config)
        .then(res => {
            dispatch({
                type: SEND_MAIL_SUCCESS,
                payload: res.status
            });
        })
        .catch(err => {
            dispatch({
                type: SEND_MAIL_FAIL,
                payload: err
            });
        });
}
