import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import { withNamespaces } from 'react-i18next';

import './Anasayfa.css';
import AcreLogo from '../../res/acrelogo.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faSuitcase, faTasks, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

function Anasayfa({ t }) {
    return (
        <div className="container">
            <Helmet>
                <title>ACRE Group</title>
            </Helmet>
            <div className="landing-container">
                <div className="logo-side">
                    <img src={AcreLogo} alt="Acre Group" className="acre-logo" />
                    <p className="small-explanation pd-t1 pd-b1">
                        {t('Aciklama')}
                    </p>
                    <Link to="/hakkimizda" className="btn-primary mrg-t1">{t('Devami')}</Link>
                </div>
                <div className="links-side">
                    <div className="links-container">
                        <div className="big-link bg-buildings mrg-r1p">
                            <Link to="/hakkimizda" className="link">
                                <div className="link-content">
                                    <FontAwesomeIcon icon={faBuilding} size="4x" />
                                    <div className="divider mrg-t1"></div>
                                    <h3 className="mrg-t1">{t('Hakkimizda')}</h3>
                                    <h4>about us</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="small-link bg-services">
                            <Link to="/hizmetlerimiz" className="link">
                                <div className="link-content">
                                    <FontAwesomeIcon icon={faSuitcase} size="4x" />
                                    <div className="divider mrg-t1"></div>
                                    <h3 className="mrg-t1">{t('Hizmetlerimiz')}</h3>
                                    <h4>our services</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="links-container">
                        <div className="small-link bg-projects mrg-r1p">
                            <Link to="/projeler" className="link">
                                <div className="link-content">
                                    <FontAwesomeIcon icon={faTasks} size="4x" />
                                    <div className="divider mrg-t1"></div>
                                    <h3 className="mrg-t1">{t('Projeler')}</h3>
                                    <h4>projects</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="big-link bg-contact">
                            <Link to="/iletisim" className="link">
                                <div className="link-content">
                                    <FontAwesomeIcon icon={faPaperPlane} size="4x" />
                                    <div className="divider mrg-t1"></div>
                                    <h3 className="mrg-t1">{t('Iletisim')}</h3>
                                    <h4>contact</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withNamespaces()(Anasayfa);
