import app from 'firebase/app';
import 'firebase/storage';


var firebaseConfig = {
    apiKey: process.env.REACT_APP_ACRE_FIREBASE_CONFIG_APIKEY || 'AIzaSyDp1WGS18iI3sk204Zn_Yw-1o7KT4oBvRU',
    authDomain: process.env.REACT_APP_ACRE_FIREBASE_AUTHDOMAIN || 'acreweb.firebaseapp.com',
    databaseURL: process.env.REACT_APP_ACRE_FIREBASE_DATABASEURL || 'https://acreweb.firebaseio.com',
    projectId: process.env.REACT_APP_ACRE_FIREBASE_PROJECTID || 'acreweb',
    storageBucket: process.env.REACT_APP_ACRE_FIREBASE_STORAGEBUCKET || 'acreweb.appspot.com',
    messagingSenderId: process.env.REACT_APP_ACRE_FIREBASE_MESSAGINGSENDERID || '399099066789',
    appId: process.env.REACT_APP_ACRE_FIREBASE_APPID || '1:399099066789:web:84762a68a0671d598ed04c',
    measurementId: process.env.REACT_APP_ACRE_FIREBASE_MEASUREMENTID || 'G-NYQ86SB6TQ'
}

var firebaseConfig2 = {
    apiKey: process.env.REACT_APP_ACRE_FIREBASE_CONFIG_APIKEY || 'AIzaSyDp1WGS18iI3sk204Zn_Yw-1o7KT4oBvRU',
    authDomain: process.env.REACT_APP_ACRE_FIREBASE_AUTHDOMAIN || 'acreweb.firebaseapp.com',
    databaseURL: process.env.REACT_APP_ACRE_FIREBASE_DATABASEURL || 'https://acreweb.firebaseio.com',
}

// Initialize Firebase
app.initializeApp(firebaseConfig);

var secondaryApp = app.initializeApp(firebaseConfig2, "Secondary")

// Get a reference to the storage service, which is used to create references in your storage bucket
var storage = app.storage();

// Create a storage reference from our storage service
var storageRef = storage.ref();

// var listRef = storageRef.child('Projects/Background');


// listRef.listAll().then(function(res) {
//   res.prefixes.forEach(function(folderRef) {
    
//     console.log(folderRef, "folder")
//   });
//   res.items.forEach(function(itemRef) {
    
//     console.log(itemRef, "item")
//   });
// }).catch(function(error) {
  
//   console.log(error)
// });


export {
    storage, storageRef, secondaryApp, app as default
};
