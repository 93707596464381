import React, { useState, useEffect, useRef } from 'react';
import i18n from '../../i18n';

import { stateSelectedLang } from '../../Actions/Auth'

import './Languages.css';

import usa from '../../res/usa.png'
import tr from '../../res/turkey.png'
import { useSelector, useDispatch } from 'react-redux';

function Languages() {
    const container = useRef(null)

    const language = useSelector(state => state.auth.selectedLang)
    const dispatch = useDispatch()

    const handleClickOutside = e => {
        if (container.current && !container.current.contains(e.target)) {
            setOpen(false)
        }
    }

    const [open, setOpen] = useState(false)
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        stateSelectedLang(dispatch, lng)
        setOpen(false)
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false)
        return () => {
            document.removeEventListener("click", handleClickOutside, false)
        }
    }, [])

    return (
        <div className="languages-controller" ref={container}>
            <div className="languages" onClick={() => setOpen(!open)}>
                <img src={language !== "en" ? tr : usa} alt={language !== "en" ? "tr" : "en"} style={{width:"30px", height:"30px"}} />
            </div>
            {open &&
                <ul>
                    <li
                        className={language === "tr" ? "selected-languages" : ""}
                        onClick={() => changeLanguage('tr')}>
                        <img src={tr} alt="tr" style={{width:"25px", height:"25px"}}/>
                        Türkçe
                    </li>
                    <li
                        className={language === "en" ? "selected-languages" : ""}
                        onClick={() => changeLanguage('en')}>
                        <img src={usa} alt="en" style={{width:"25px", height:"25px"}}/>
                        English
                    </li>
                </ul>
            }
        </div>
    )
}

export default Languages
