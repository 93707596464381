import 'firebase/auth';
import app, { secondaryApp } from '../Firebase';

import {
  AUTH_FAIL, AUTH_LOADING,
  AUTH_SUCCESS, CREATE_USER_FAIL, CREATE_USER_LOADING,
  CREATE_USER_SUCCESS, GET_IP_FAIL, GET_IP_LOADING,
  GET_IP_SUCCESS, LOGOUT_LOADING, LOGOUT_SUCCESS, SELECTED_LANG
} from './Types';

export const authLogin = (email, password) => (dispatch) => {
  dispatch({ type: AUTH_LOADING });

  app
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((res) => {
      dispatch({
        type: AUTH_SUCCESS,
        payload: res.user,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTH_FAIL,
        payload: err,
      });
    });
};

export const currentUser = () => (dispatch) => {
  dispatch({ type: AUTH_LOADING });
  app.auth().onAuthStateChanged((user) => {
    if (user) {
      dispatch({
        type: AUTH_SUCCESS,
        payload: user,
      });
    } else {
      dispatch({
        type: AUTH_FAIL,
        payload: null,
      });
    }
  });
};

export const logOut = () => (dispatch) => {
  dispatch({ type: LOGOUT_LOADING });

  app
    .auth()
    .signOut()
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getIP = () => (dispatch) => {
  dispatch({ type: GET_IP_LOADING });

  fetch("https://api.ipbase.com/v2/info?apikey=rjHBJS4P1qDh9fASHfOwdJRcReqTWTkaAmfOhHJ6")
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: GET_IP_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: GET_IP_FAIL,
        payload: err,
      });
    });
};

export const createUser = (email, sifre) => (dispatch) => {
  dispatch({ type: CREATE_USER_LOADING });

  secondaryApp
    .auth()
    .createUserWithEmailAndPassword(email, sifre)
    // .then(function (result) {
    //     return result.user.updateProfile({
    //         displayName: "user"
    //     })
    // })
    .then((res) => {
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: res,
      });
      secondaryApp.auth().signOut();
    })
    .catch((err) => {
      dispatch({
        type: CREATE_USER_FAIL,
        payload: err,
      });
    });
};

export const stateSelectedLang = (dispatch, lng) => {
  dispatch({
    type: SELECTED_LANG,
    payload: lng,
  });
};
