import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { currentUser } from "../Actions/Auth";


import Loading from './Loading/Loading';

function PrivateRoute({ component: Component, ...rest }) {
    const auth = useSelector(state => state.auth);
    // const dispatch = useDispatch()
    // const pendingLoad = useSelector(state => state.auth.pendingLoad)


    // useEffect(() => {
    //     currentUser(dispatch)
    // }, [dispatch, pendingLoad])

    return (
        <Route
            {...rest}
            render={
                props => {
                    if (auth.isLoading_auth) {
                        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }}>
                            <Loading />
                        </div>
                    } else if (!auth.isAuthenticated && !auth.isLoading_auth) {
                        return <Redirect to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }} />
                    } else if ((auth.success_auth) && (auth.success_auth.uid !== "MpAjGItZXBM3dK3uJBjAg468C6C3")) {
                        return <Redirect to={{
                            pathname: "/projeler",
                            state: { from: props.location }
                        }} />
                    } else {
                        return (
                            <Component {...props} />
                        )
                    }
                }
            }
        />
    )
}

export default PrivateRoute