import React, { Component } from 'react';
import axios from 'axios';

import { withNamespaces } from 'react-i18next';

import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaSiteKey } from '../../constants'

import './RequestForm.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Loading from '../Loading/Loading'
import SecondaryButton from '../Buttons/SecondaryButton';

export class RequestForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            message: {
                Adiniz: "",
                Email: "",
                SirketAdi: "",
                Telefon: "",
                Talep: "",
                Subject: "Kullanıcı Talebi"
            },
            reCaptcha: false
        }
    }

    handleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        this.setState(prevState => ({
            ...prevState,
            "message": {
                ...prevState["message"],
                [name]: e.target.value
            }
        }))
    }

    handleChangeCaptcha = (value) => {
        const body = JSON.stringify({ response: value })
        const config = { headers: { "Content-Type": "application/json" } };
        axios
            .post('https://us-central1-acreweb.cloudfunctions.net/checkCaptcha', body, config)

        if (value) { this.setState({ reCaptcha: true }) } else { this.setState({ reCaptcha: false }) }
    }

    handleRefreshPage = (e) => {
        e.preventDefault()
        window.location.reload(false)
    }

    render() {
        let recaptchaRef = React.createRef()
        const { t } = this.props
        return (
            <div className="request-form-container">
                <div className="login-inputs">
                    {this.props.send_mail_loading ?
                        <div className="popup">
                            <div className="popupInner">
                                <div className="popup-alert-container">
                                    <Loading />
                                </div>
                            </div>
                        </div>
                        : this.props.send_mail_success ?
                            <div className="popup">
                                <div className="popupInner">
                                    <div className="popup-alert-container">
                                        <div className="success-alert2">{t('Kullanici_Talep')}</div>
                                        <SecondaryButton
                                            onClick={this.handleRefreshPage}
                                            content={t('Tamam')} />
                                    </div>
                                </div>
                            </div>
                            : this.props.send_mail_fail ?
                                <div className="popup">
                                    <div className="popupInner">
                                        <div className="popup-alert-container">
                                            <div className="request-reason">{t('Hata_Olustu')}</div>
                                            <SecondaryButton
                                                onClick={this.handleRefreshPage}
                                                content={t('Tamam')} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="request-reason">
                                        {t('Proje_Goruntuleme')}
                                    </div>
                                    <div className="request-reason mrg-t1">
                                        {t('Kisisel_Veri')}
                                    </div>
                                    <form onChange={this.handleChange} className="login-inputs">
                                        <label htmlFor="Adiniz">{t('Ad_Soyad')}</label>
                                        <input type="text" id="Adiniz" name="Adiniz" placeholder={t('Ad_Soyad')} />
                                        <label htmlFor="SirketAdi">{t('Firma_Adi')}</label>
                                        <input type="text" id="SirketAdi" name="SirketAdi" placeholder={t('Firma_Adi')} />
                                        <label htmlFor="Telefon">{t('Telefon_No')}</label>
                                        <input type="text" id="Telefon" name="Telefon" placeholder={t('Telefon_No')} />
                                        <label htmlFor="Email">{t('Eposta')}</label>
                                        <input type="email" id="Email" name="Email" placeholder={t('Eposta')} />
                                        <label htmlFor="Talep">{t('Talep_Nedeni')}</label>
                                        <textarea type="text" id="Talep" name="Talep" placeholder={t('Aciklama_Iste')} />
                                    </form>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <ReCAPTCHA
                                            ref={res => recaptchaRef = res}
                                            sitekey={recaptchaSiteKey}
                                            onChange={this.handleChangeCaptcha}
                                        />
                                    </div>
                                    {this.props.errorForm}
                                    <button onClick={() => this.props.handleRequest(this.state.message, this.state.reCaptcha)}>{t('Istekte_Bulun')}</button>
                                    <Link to="/login" className="giris-yap-link mrg-t1">{t('Giris')}</Link>
                                </>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    send_mail_loading: state.mail.send_mail_loading,
    send_mail_success: state.mail.send_mail_success,
    send_mail_fail: state.mail.send_mail_fail
})

export default connect(mapStateToProps)(withNamespaces()(RequestForm))
