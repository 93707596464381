import {
    GET_PROJECT_LIST_FAIL,
    GET_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST_LOADING,
    POST_NEW_PROJECT_LOADING,
    POST_NEW_PROJECT_SUCCESS,
    POST_NEW_PROJECT_FAIL,
    UPDATE_PROJECT_LOADING,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAIL,
    DELETE_PROJECT_LOADING,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAIL,
    GET_IMAGES_LOADING,
    GET_IMAGES_SUCCESS,
    GET_IMAGES_FAIL,
    SELECTED_TAB,
    SCROLL_POS
} from '../Actions/Types'

const initialState = {
    get_project_loading: false,
    get_project_list: null,
    get_project_fail: null,
    post_new_project_loading: false,
    post_new_project_success: null,
    post_new_project_fail: null,
    update_project_loading: false,
    update_project_success: null,
    update_project_fail: null,
    delete_project_loading: false,
    delete_project_success: null,
    delete_project_fail: null,
    images_loading: false,
    images_list: null,
    images_fail: null,
    selectedTab: "Tamamlanan",
    scrollPos: 0
}

export default function projeler(state = initialState, action) {
    switch (action.type) {
        case GET_PROJECT_LIST_LOADING:
            return {
                ...state,
                get_project_loading: true
            }
        case GET_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                get_project_loading: false,
                get_project_list: action.payload.docs,
                get_project_fail: null
            }
        case GET_PROJECT_LIST_FAIL:
            return {
                ...state,
                get_project_loading: false,
                get_project_list: null,
                get_project_fail: action.payload
            }
        case POST_NEW_PROJECT_LOADING:
            return {
                ...state,
                post_new_project_loading: true,
            }
        case POST_NEW_PROJECT_SUCCESS:
            return {
                ...state,
                post_new_project_loading: false,
                post_new_project_success: action.payload,
                post_new_project_fail: null
            }
        case POST_NEW_PROJECT_FAIL:
            return {
                ...state,
                post_new_project_loading: false,
                post_new_project_success: null,
                post_new_project_fail: action.payload
            }
        case UPDATE_PROJECT_LOADING:
            return {
                ...state,
                update_project_loading: true
            }
        case UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                update_project_loading: false,
                update_project_success: action.payload,
                update_project_fail: null,
            }
        case UPDATE_PROJECT_FAIL:
            return {
                ...state,
                update_project_loading: false,
                update_project_success: null,
                update_project_fail: action.payload,
            }
        case DELETE_PROJECT_LOADING:
            return {
                ...state,
                delete_project_loading: true
            }
        case DELETE_PROJECT_SUCCESS:
            return {
                ...state,
                delete_project_loading: false,
                delete_project_success: action.payload,
                delete_project_fail: null
            }
        case DELETE_PROJECT_FAIL:
            return {
                ...state,
                delete_project_loading: false,
                delete_project_success: null,
                delete_project_fail: action.payload
            }
        case GET_IMAGES_LOADING:
            return {
                ...state,
                images_loading: true
            }
        case GET_IMAGES_SUCCESS:
            return {
                ...state,
                images_loading: false,
                images_list: action.payload,
                images_fail: null
            }
        case GET_IMAGES_FAIL:
            return {
                ...state,
                images_loading: false,
                // images_list: null,
                images_fail: action.payload
            }
        case SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.payload,
            }
        case SCROLL_POS:
            return {
                ...state,
                scrollPos: action.payload
            }
        default:
            return state;
    }
} 