import React, { Component } from 'react'
import './Iletisim.css'
import { Helmet } from 'react-helmet'
import axios from 'axios';

import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Email } from '../../Actions/Mail'
import { connect } from 'react-redux'
import Loading from '../Loading/Loading'
import SecondaryButton from '../Buttons/SecondaryButton';
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaSiteKey } from '../../constants'

export class Iletisim extends Component {
    constructor(props) {
        super(props)

        this.state = {
            message: {
                Adiniz: "",
                Email: "",
                Telefon: "",
                SirketAdi: "",
                Talep: "",
                Subject: "İstek&Görüş"
            },
            reCaptcha: false
        }
    }

    handleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        this.setState(prevState => ({
            ...prevState,
            "message": {
                ...prevState["message"],
                [name]: e.target.value
            }
        }))
    }

    handleChangeCaptcha = (value) => {
        const body = JSON.stringify({ response: value })
        const config = { headers: { "Content-Type": "application/json" } };
        axios
            .post('https://us-central1-acreweb.cloudfunctions.net/checkCaptcha', body, config)

        if (value) { this.setState({ reCaptcha: true }) } else { this.setState({ reCaptcha: false }) }
    }

    handleRequest = (e, message) => {
        e.preventDefault()
        if (!message.Adiniz || !message.Email || !message.SirketAdi || !message.Talep) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Tum_Alanlari_Doldur')}</div>
            })
        } else if (!message.Email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Gecerli_Mail')}</div>
            })
        } else if (!this.state.reCaptcha) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Recaptcha_Dogrula')}</div>
            })
        } else {
            this.props.Email(message)
            this.setState({
                errorForm: null
            })
        }
    }

    handleRefreshPage = (e) => {
        e.preventDefault()
        window.location.reload(false)
    }

    render() {
        let recaptchaRef = React.createRef()
        const { t } = this.props
        return (
            <div className="fluid top-border">
                <Helmet>
                    <title>{t('Title_Iletisim')}</title>
                </Helmet>
                <div className="fluid-container flx-cont">
                    <div className="iletisim-container">
                        <div className="iletisim-content">
                            <p className="iletisim-header">{t('Iletisim_Bilgileri')}</p>
                            <p className="iletisim-title">
                                <FontAwesomeIcon icon={faMap} size="lg" /> <span className="mrg-l05">{t('Adres')}</span>
                            </p>
                            <p className="iletisim-aciklama">
                                İnönü Mah. Fatih Sultan Mehmet Cad. No:17 GGOSB/KOCAELİ
                        </p>
                        </div>
                        <div className="iletisim-content">
                            <p className="iletisim-title">
                                <FontAwesomeIcon icon={faMobileAlt} size="lg" /> <span className="mrg-l05">{t('Telefon')}</span>
                            </p>
                            <p className="iletisim-aciklama">
                                <a href="tel:02627512150">0262 644 09 25</a>
                            </p>
                        </div>
                        <div className="iletisim-content">
                            <p className="iletisim-title">
                                <FontAwesomeIcon icon={faEnvelope} size="lg" /> <span className="mrg-l05">{t('Eposta')}</span>
                            </p>
                            <p className="iletisim-aciklama">
                                <a href="mailto:info@acregroup.com.tr">info@acregroup.com.tr</a>
                            </p>
                        </div>
                        <div className="harita">
                            <iframe title="Acre Group Real Estate Location"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.4858867167095!2d29.43496101586453!3d40.83925487931834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb27495bc57a09%3A0x84c8ab817bc59a3c!2sGebze%20G%C3%BCzeller%20Organize%20Sanayi%20B%C3%B6lgesi!5e0!3m2!1str!2str!4v1605830403069!5m2!1str!2str"
                                width="100%" height="100%" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                        </div>
                    </div>
                    <div className="harita-container">
                        <p className="iletisim-header">{t('Iletisim_Form')}</p>
                        <p className="iletisim-title">{t('Iletisim_Aciklama')}</p>
                        {this.state.errorForm}
                        {this.props.send_mail_loading ?
                            <div className="popup">
                                <div className="popupInner">
                                    <div className="popup-alert-container">
                                        <Loading />
                                    </div>
                                </div>
                            </div>
                            : this.props.send_mail_success ?
                                <div className="popup">
                                    <div className="popupInner">
                                        <div className="popup-alert-container">
                                            <div className="success-alert">{t('Mesaj_Ulasti')}</div>
                                            <SecondaryButton
                                                onClick={this.handleRefreshPage}
                                                content={t('Tamam')} />
                                        </div>
                                    </div>
                                </div>
                                : this.props.send_mail_fail ?
                                    <div className="popup">
                                        <div className="popupInner">
                                            <div className="popup-alert-container">
                                                <div className="request-reason">{t('Hata_Olustu')}</div>
                                                <SecondaryButton
                                                    onClick={this.handleRefreshPage}
                                                    content={t('Tamam')} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <form onChange={this.handleChange} className="login-inputs">
                                        <div className="flex-wrap">
                                            <div className="iletisim-left">
                                                <input type="text" id="Adiniz" name="Adiniz" placeholder={t('Ad_Soyad')} />
                                                <input type="text" id="Telefon" name="Telefon" placeholder={t('Telefon_No')} />
                                                <input type="text" id="SirketAdi" name="SirketAdi" placeholder={t('Firma_Adi')} />
                                                <input type="email" id="Email" name="Email" placeholder={t('Eposta')} />
                                            </div>
                                            <div className="iletisim-right">
                                                <textarea type="text" id="Talep" name="Talep" placeholder={t('Mesaj')} />
                                            </div>
                                        </div>
                                        <div className="flex-wrap">
                                            <div className="iletisim-left">
                                                <div className="recaptcha-container">
                                                    <ReCAPTCHA
                                                        ref={res => recaptchaRef = res}
                                                        sitekey={recaptchaSiteKey}
                                                        onChange={this.handleChangeCaptcha}
                                                    />
                                                </div>
                                            </div>
                                            <div className="iletisim-right">
                                                <button
                                                    onClick={e => this.handleRequest(e, this.state.message)}
                                                >{t('Gonder')}</button>
                                            </div>
                                        </div>
                                    </form>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    send_mail_loading: state.mail.send_mail_loading,
    send_mail_success: state.mail.send_mail_success,
    send_mail_fail: state.mail.send_mail_fail
})

export default connect(mapStateToProps, { Email })(withNamespaces()(Iletisim))
