import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Loading from '../Loading/Loading';

import { withNamespaces } from 'react-i18next';

import { logOut } from '../../Actions/Auth';


class Logout extends PureComponent {
	componentDidMount() {
		this.props.logOut()
	}

	render() {

		if (!this.props.isLoading_logout && !this.props.isAuthenticated) {
			return (
				<Redirect to="/login" />
			)
		} else {
			return (
				<div className='popup'>
					<div className='popupInner'>
						<div style={{ padding: "50px", textAlign: "center" }}>
							<Loading />
							<p><b>{this.props.t("Cikis_Yap")}</b></p>
						</div>
					</div>
				</div>
			)
		}
	}
}

const mapStateToProps = state => ({
	isLoading_logout: state.auth.isLoading_logout,
	isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { logOut })(withNamespaces()(Logout))
