import React from 'react';
import logo from '../../res/acrelogo.svg'
import './AcreLoading.css'

function AcreLoading() {
    return (
        <div className="showbox">
            <img src={logo} alt="Acre Group Logo" className="loading-logo mrg-auto" />
        </div>
    )
}

export default AcreLoading
