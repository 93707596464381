import app, { storageRef } from '../Firebase';
import 'firebase/firestore';
import {
    GET_PROJECT_LIST_LOADING,
    GET_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST_FAIL,
    POST_NEW_PROJECT_SUCCESS,
    POST_NEW_PROJECT_LOADING,
    POST_NEW_PROJECT_FAIL,
    UPDATE_PROJECT_LOADING,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAIL,
    DELETE_PROJECT_LOADING,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAIL,
    GET_IMAGES_LOADING,
    GET_IMAGES_SUCCESS,
    GET_IMAGES_FAIL,
    SELECTED_TAB,
    SCROLL_POS
} from './Types'

export const getProjectList = () => dispatch => {
    dispatch({ type: GET_PROJECT_LIST_LOADING })

    app.firestore().collection('projects').get()
        .then(res => {
            dispatch({
                type: GET_PROJECT_LIST_SUCCESS,
                payload: res
            })
        })
        .catch(err => {
            dispatch({
                type: GET_PROJECT_LIST_FAIL,
                payload: err
            })

        })
}

export const postNewProject = (Proje_Adi, Proje_Aciklama, Proje_Ozet, Proje_Adi_EN, Proje_Aciklama_EN, Proje_Ozet_EN, Adres, Lokasyon, Harita, Yapim_Yili, Filtre, Status, id) => dispatch => {
    dispatch({ type: POST_NEW_PROJECT_LOADING })

    // app.firestore().collection('projects').add({
    app.firestore().collection('projects').doc(id).set({
        "Proje Adi": Proje_Adi,
        "Proje Aciklama": Proje_Aciklama,
        "Proje Ozet": Proje_Ozet,
        "EN_Adi": Proje_Adi_EN,
        "EN_Aciklama": Proje_Aciklama_EN,
        "EN_Ozet": Proje_Ozet_EN,
        "Adres": Adres,
        "Lokasyon": Lokasyon,
        "Harita": Harita,
        "Yapim Yili": Yapim_Yili,
        "Filtre": Filtre,
        "Status": Status
    })
        .then(res => {
            dispatch({
                type: POST_NEW_PROJECT_SUCCESS,
                payload: true
            })
        })
        .catch(err => {
            dispatch({
                type: POST_NEW_PROJECT_FAIL,
                payload: err
            })

        })
}

export const updateProject = (Proje_Adi, Proje_Aciklama, Proje_Ozet, Proje_Adi_EN, Proje_Aciklama_EN, Proje_Ozet_EN, Adres, Lokasyon, Harita, Yapim_Yili, Filtre, Status, id) => dispatch => {
    dispatch({ type: UPDATE_PROJECT_LOADING })

    app.firestore().collection('projects').doc(id).update({
        "Proje Adi": Proje_Adi,
        "Proje Aciklama": Proje_Aciklama,
        "Proje Ozet": Proje_Ozet,
        "EN_Adi": Proje_Adi_EN,
        "EN_Aciklama": Proje_Aciklama_EN,
        "EN_Ozet": Proje_Ozet_EN,
        "Adres": Adres,
        "Lokasyon": Lokasyon,
        "Harita": Harita,
        "Yapim Yili": Yapim_Yili,
        "Filtre": Filtre,
        "Status": Status
    })
        .then(res => {
            dispatch({
                type: UPDATE_PROJECT_SUCCESS,
                payload: true
            })
        })
        .catch(err => {
            dispatch({
                type: UPDATE_PROJECT_FAIL,
                payload: true
            })

        })
}

export const deleteProject = (id) => dispatch => {
    dispatch({ type: DELETE_PROJECT_LOADING })

    app.firestore().collection('projects').doc(id).delete()
        .then(res => {
            dispatch({
                type: DELETE_PROJECT_SUCCESS,
                payload: true
            })
        })
        .catch(err => {
            dispatch({
                type: DELETE_PROJECT_FAIL,
                payload: true
            })

        })
}

export const getProjectImages = (id) => dispatch => {
    dispatch({ type: GET_IMAGES_LOADING })

    storageRef.child(`/Projects/Posts/${id}`).listAll()
        .then(res => {
            dispatch({
                type: GET_IMAGES_SUCCESS,
                payload: res.items
            })
        })
        .catch(err => {
            dispatch({
                type: GET_IMAGES_FAIL,
                payload: err
            })
        })
}

export const stateSelectedTab = (tab) => dispatch => {
    dispatch({
        type: SELECTED_TAB,
        payload: tab,
    })
}

export const handleScrollPos = (scrollPos) => dispatch => {
    dispatch({
        type: SCROLL_POS,
        payload: scrollPos,
    })
}