import React from 'react';
import './SecondaryButton.css'

function SecondaryButton(props) {
    return (
        <button onClick={props.onClick} className="btn-secondary">
            {props.content}
        </button>
    )
}

export default SecondaryButton