export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOADING = "AUTH_LOADING";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_LOADING = "LOGOUT_LOADING"

export const GET_PROJECT_LIST_LOADING = "GET_PROJECT_LIST_LOADING"
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS"
export const GET_PROJECT_LIST_FAIL = "GET_PROJECT_LIST_FAIL"

export const POST_NEW_PROJECT_LOADING = "POST_NEW_PROJECT_LOADING"
export const POST_NEW_PROJECT_SUCCESS = "POST_NEW_PROJECT_SUCCESS"
export const POST_NEW_PROJECT_FAIL = "POST_NEW_PROJECT_FAIL"

export const UPDATE_PROJECT_LOADING = "UPDATE_PROJECT_LOADING"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL"

export const DELETE_PROJECT_LOADING = "DELETE_PROJECT_LOADING"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"

export const GET_IMAGES_LOADING = "GET_IMAGES_LOADING"
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS"
export const GET_IMAGES_FAIL = "GET_IMAGES_FAIL"

export const GET_IP_LOADING =  "GET_IP_LOADING"
export const GET_IP_SUCCESS = "GET_IP_SUCCESS"
export const GET_IP_FAIL = "GET_IP_FAIL"

export const CREATE_USER_LOADING = "CREATE_USER_LOADING"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"

export const SEND_MAIL_LOADING = "SEND_MAIL_LOADING"
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS"
export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL"

export const SELECTED_TAB = "SELECTED_TAB"
export const SELECTED_LANG = "SELECTED_LANG"
export const SCROLL_POS = "SCROLL_POS"