import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import './App.css';

import Anasayfa from './Components/Anasayfa/Anasayfa';
import Dashboard from './Components/Dashboard/Dashboard';
import Hizmetlerimiz from './Components/Hizmetlerimiz/Hizmetlerimiz';
import Iletisim from './Components/Iletisim/Iletisim';
import Login from './Components/Login/Login';
import Logout from './Components/Login/Logout';
import Navbar from './Components/Navbar/Navbar';
import PrivateRoute from './Components/PrivateRoute';
import ProjeDetay from './Components/Projeler/ProjeDetay';
import Projeler from './Components/Projeler/Projeler';
import NotFound404 from './NotFound404';
import ScrollToTop from './ScrollToTop.js';

import { currentUser, getIP } from './Actions/Auth';

import Hakkimizda from './Components/Hakkımızda/Hakkimizda';
import AcreLoading from './Components/Loading/AcreLoading';

import Languages from './Components/Buttons/Languages';
import LoginButton from './Components/Buttons/LoginButton';

function App() {
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement({ pageLanguage: 'tr', includedLanguages: "en,ru,ar,fr" }, 'google_translate_element')
  // }

  // useEffect(() => {
  //   var addScript = document.createElement('script');
  //   addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, [])

  const dispatch = useDispatch()

  const { loading, isLoading_auth } = useSelector(
    state => ({
      loading: state.auth.isLoading_ip,
      success: state.auth.success_ip,
      error: state.auth.fail_ip,
      isLoading_auth: state.auth.isLoading_auth
    })
  );

  useEffect(() => {
    dispatch(getIP());
    dispatch(currentUser())
  }, [dispatch])

  return (
    <>
      {loading || isLoading_auth ?
        <AcreLoading />
        // : error ? <div>500 Internal Server Error</div>
          :
          <main className="mainpage">
            {/* <div id="google_translate_element"></div> */}
            <ScrollToTop />
            <div className="main-buttons-container">
              <LoginButton />
              <Languages />
            </div>
            <Switch>
              <Route exact path="/anasayfa">
                <Anasayfa />
              </Route>
              <Route exact path="/hizmetlerimiz">
                <Navbar />
                <Hizmetlerimiz />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <Route exact path="/projeler">
                <Navbar />
                <Projeler />
              </Route>
              <Route exact path="/iletisim">
                <Navbar />
                <Iletisim />
              </Route>
              <Route exact path="/hakkimizda">
                <Navbar />
                <Hakkimizda />
              </Route>
              <Route exact path="/projeler/:projeDurum/:proje" children={({ match }) => (<div>
                <Navbar />
                <ProjeDetay match={match} />
              </div>)}>
              </Route>
              <Route exact path="/logout">
                <Logout />
              </Route>
              <Route exact path="/">
                <Anasayfa />
              </Route>
              <Route path="*">
                <NotFound404 />
              </Route>
            </Switch>
          </main>
      }
    </>
  )
}

export default App;
