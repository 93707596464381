import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
// import NavbarLogged from '../Navbar/NavbarLogged';
import Sidebar from '../Sidebar/Sidebar';
import './Dashboard.css';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import {
  postNewProject,
  getProjectList,
  updateProject,
  deleteProject,
  getProjectImages,
} from '../../Actions/Projeler';
import { createUser } from '../../Actions/Auth';
import { Email } from '../../Actions/Mail';
import { connect } from 'react-redux';
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { storageRef } from '../../Firebase';
import { uuid } from '../../constants';
import { baseImgUrl } from '../../constants';

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      newPost: false,
      Proje_Adi: null,
      Proje_Aciklama: null,
      Proje_Ozet: null,
      Proje_Adi_EN: null,
      Proje_Aciklama_EN: null,
      Proje_Ozet_EN: null,
      Adres: null,
      Lokasyon: null,
      Harita: null,
      Yapim_Yili: null,
      Filtre: '#8586B4',
      Status: null,
      Background: null,
      Fotograflar: null,
      errorForm: null,
      selectedProjectId: null,
      isSelectedProject: false,
      progress: null,
      progress_coklu: null,
      createShow: false,
      Email: null,
      Sifre: null,
      id: uuid,
      deletedImageArray: [],
    };

    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.getProjectList();
  }

  handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    this.setState((prevState) => ({
      ...prevState,
      [name]: e.target.files ? e.target.files : e.target.value,
    }));
  };

  handleNewPost = (e) => {
    e.preventDefault();
    this.setState({
      newPost: true,
    });
  };

  handleUploadImage = (e, id) => {
    e.preventDefault();
    const metadata = {
      contentType: 'image/jpg',
    };
    const uploadTask = storageRef
      .child(`/Projects/Background/${id}.jpg`)
      .put(this.state.Background[0], metadata);
    // initiates the firebase side uploading
    uploadTask.on(
      'state_changed',
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        const progress =
          (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
        this.setState({
          progress: progress,
        });
      },
      (err) => {
        //catches the errors
        const progress = 'Failed';
        this.setState({
          progress: progress,
        });
      }
    );
  };

  handleUploadImageMultiple = (e, id) => {
    e.preventDefault();
    const metadata = {
      contentType: 'image/jpg',
    };
    for (let i = 0; i < this.state.Fotograflar.length; i++) {
      const uploadTask = storageRef
        .child(
          `/Projects/Posts/${id}/${this.state.Fotograflar[i].name.replace(
            /\.[^/.]+$/,
            ''
          )}.jpg`
        )
        .put(this.state.Fotograflar[i], metadata);
      // initiates the firebase side uploading
      uploadTask.on(
        'state_changed',
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          let progress_coklu =
            (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
          this.setState({
            progress_coklu: progress_coklu,
          });
        },
        (err) => {
          //catches the errors
          const progress_coklu = 'Failed';
          this.setState({
            progress_coklu: progress_coklu,
          });
        }
      );
    }
  };

  handleSavePost = (e, type) => {
    e.preventDefault();
    const Proje_Adi = this.state.Proje_Adi;
    const Proje_Aciklama = this.state.Proje_Aciklama;
    const Proje_Ozet = this.state.Proje_Ozet;
    const Proje_Adi_EN = this.state.Proje_Adi_EN;
    const Proje_Aciklama_EN = this.state.Proje_Aciklama_EN;
    const Proje_Ozet_EN = this.state.Proje_Ozet_EN;
    const Adres = this.state.Adres;
    const Lokasyon = this.state.Lokasyon;
    const Harita = this.state.Harita
      ? this.state.Harita
      : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12073.943524884773!2d29.43715!3d40.839255!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x84c8ab817bc59a3c!2sGebze%20G%C3%BCzeller%20Organize%20Sanayi%20B%C3%B6lgesi!5e0!3m2!1str!2str!4v1607427463548!5m2!1str!2str';
    const Yapim_Yili = this.state.Yapim_Yili;
    const Filtre = this.state.Filtre + '95';
    const Status = this.state.Status;
    const id = this.state.id;
    const selectedID = this.state.selectedProjectId;
    const Background = this.state.Background;
    const Fotograflar = this.state.Fotograflar;

    const validateForm = () => {
      return (
        !!Proje_Adi &&
        !!Proje_Aciklama &&
        !!Proje_Ozet &&
        !!Adres &&
        !!Lokasyon &&
        !!Yapim_Yili &&
        !!Filtre &&
        !!Status &&
        !!Proje_Adi_EN &&
        !!Proje_Aciklama_EN &&
        !!Proje_Ozet_EN
      );
    };

    if (validateForm() === true && type === 'save') {
      this.props.postNewProject(
        Proje_Adi,
        Proje_Aciklama,
        Proje_Ozet,
        Proje_Adi_EN,
        Proje_Aciklama_EN,
        Proje_Ozet_EN,
        Adres,
        Lokasyon,
        Harita,
        Yapim_Yili,
        Filtre,
        Status,
        id
      );

      if (Background) {
        this.handleUploadImage(e, id);
      }
      if (Fotograflar) {
        this.handleUploadImageMultiple(e, id);
      }
    } else if (validateForm() === true && type === 'update') {
      this.props.updateProject(
        Proje_Adi,
        Proje_Aciklama,
        Proje_Ozet,
        Proje_Adi_EN,
        Proje_Aciklama_EN,
        Proje_Ozet_EN,
        Adres,
        Lokasyon,
        Harita,
        Yapim_Yili,
        Filtre,
        Status,
        selectedID
      );

      if (Background) {
        this.handleUploadImage(e, selectedID);
      }
      if (Fotograflar) {
        this.handleUploadImageMultiple(e, selectedID);
      }
    } else {
      this.setState({
        errorForm: (
          <div className='error-alert unhidden mrg-t1'>
            Lütfen bilgileri eksiksiz doldurunuz.
          </div>
        ),
      });
    }
  };

  handleProjectDetail = (e, id) => {
    e.preventDefault();
    this.props.getProjectImages(id);
    const selectedProject = this.props.get_project_list
      .filter((doc) => doc.id === id)[0]
      .data();
    const Proje_Adi = selectedProject['Proje Adi'];
    const Proje_Aciklama = selectedProject['Proje Aciklama'];
    const Proje_Ozet = selectedProject['Proje Ozet'];
    const Proje_Adi_EN = selectedProject['EN_Adi'];
    const Proje_Aciklama_EN = selectedProject['EN_Aciklama'];
    const Proje_Ozet_EN = selectedProject['EN_Ozet'];
    const Adres = selectedProject['Adres'];
    const Lokasyon = selectedProject['Lokasyon'];
    const Harita = selectedProject['Harita'];
    const Yapim_Yili = selectedProject['Yapim Yili'];
    const Filtre = selectedProject['Filtre'];
    const Status = selectedProject['Status'];
    this.setState({
      selectedProjectId: id,
      isSelectedProject: true,
      Proje_Adi: Proje_Adi,
      Proje_Aciklama: Proje_Aciklama,
      Proje_Ozet: Proje_Ozet,
      Proje_Adi_EN: Proje_Adi_EN,
      Proje_Aciklama_EN: Proje_Aciklama_EN,
      Proje_Ozet_EN: Proje_Ozet_EN,
      Adres: Adres,
      Lokasyon: Lokasyon,
      Harita: Harita,
      Yapim_Yili: Yapim_Yili,
      Filtre: Filtre.substring(0, 7),
      Status: Status,
    });
  };

  handleDelete = (e) => {
    e.preventDefault();
    this.props.deleteProject(this.state.selectedProjectId);

    storageRef
      .child(`/Projects/Background/${this.state.selectedProjectId}.jpg`)
      .delete()
      .then(() => {
        console.log('Successfully deleted');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleImageDelete = (e, path) => {
    e.preventDefault();
    storageRef
      .child(path)
      .delete()
      .then(() => {
        this.setState({
          deletedImageArray: [...this.state.deletedImageArray, path],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.setState(this.initialState);
  };

  handleRefreshPage = (e) => {
    e.preventDefault();
    window.location.reload(false);
  };

  showCreateUser = (e) => {
    e.preventDefault();
    this.setState({
      createShow: true,
    });
  };

  handleCreateUser = (e) => {
    e.preventDefault();
    const email = this.state.Email;
    const sifre = this.state.Sifre;

    if (!email || !sifre) {
      this.setState({
        errorForm: (
          <div className='error-alert unhidden mrg-t1'>
            Lütfen bilgileri eksiksiz doldurunuz.
          </div>
        ),
      });
    } else if (!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
      this.setState({
        errorForm: (
          <div className='error-alert unhidden mrg-t1'>
            Lütfen geçerli bir email adresi giriniz.
          </div>
        ),
      });
    } else if (sifre?.length < 6) {
      this.setState({
        errorForm: (
          <div className='error-alert unhidden mrg-t1'>
            Şifre en az 6 hane olmalıdır.
          </div>
        ),
      });
    } else {
      this.props.createUser(email, sifre);
      this.setState({
        errorForm: null,
      });
    }
  };

  handleSendMail = (e) => {
    e.preventDefault();
    const message = {
      To: this.state.Email,
      Sifre: this.state.Sifre,
      Subject: 'Kullanıcı Oluşturuldu',
    };
    this.props.Email(message);
  };

  render() {
    return (
      <div className='wrapper'>
        <Helmet>
          <title>Admin | ACRE Group</title>
        </Helmet>
        {this.state.newPost && (
          <div className='popup'>
            <div className='popupInner'>
              {this.props.post_new_project_loading === true ||
              (this.state.progress_coklu !== null &&
                this.state.progress_coklu !== 100) ||
              (this.state.progress !== null && this.state.progress !== 100) ? (
                <div className='popup-alert-container'>
                  <Loading />
                </div>
              ) : this.props.post_new_project_fail ? (
                <div className='popup-alert-container'>
                  <div className='error-alert'>
                    Bir hata oluştu lütfen tekrar deneyiniz.
                  </div>
                </div>
              ) : // : (this.props.post_new_project_success === true && this.state.progress === 100 && this.state.progress_coklu === 100) ?
              this.props.post_new_project_success === true &&
                (this.state.progress === 100 || this.state.progress === null) &&
                (this.state.progress_coklu === 100 ||
                  this.state.progress_coklu === null) ? (
                <div className='popup-alert-container'>
                  <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size='4x'
                      color='#155725'
                    />
                  </div>
                  <div className='success-alert'>
                    Yeni proje başarılı bir şekilde eklendi.
                  </div>
                  <SecondaryButton
                    onClick={this.handleRefreshPage}
                    content='Tamam'
                  />
                </div>
              ) : (
                <form onChange={this.handleChange}>
                  <div className='popup-alert-container'>
                    <label htmlFor='Proje Adi'>Proje Adı</label>
                    <input type='text' id='Proje Adi' name='Proje_Adi' />
                    <label htmlFor='Proje Adi ENG'>Proje Adı (ENG)</label>
                    <input type='text' id='Proje Adi ENG' name='Proje_Adi_EN' />
                    <label htmlFor='Proje Ozet'>Proje Özet</label>
                    <input type='text' id='Proje Ozet' name='Proje_Ozet' />
                    <label htmlFor='Proje Ozet ENG'>Proje Özet (ENG)</label>
                    <input
                      type='text'
                      id='Proje Ozet ENG'
                      name='Proje_Ozet_EN'
                    />
                    <label htmlFor='Proje Aciklama'>Proje Açıklama</label>
                    <textarea
                      type='text'
                      id='Proje Aciklama'
                      name='Proje_Aciklama'
                    />
                    <label htmlFor='Proje Aciklama ENG'>
                      Proje Açıklama (ENG)
                    </label>
                    <textarea
                      type='text'
                      id='Proje Aciklama ENG'
                      name='Proje_Aciklama_EN'
                    />
                    <label htmlFor='Adres'>Proje Adresi</label>
                    <input type='text' id='Adres' name='Adres' />
                    <label htmlFor='Lokasyon'>Proje Lokasyonu</label>
                    <input type='text' id='Lokasyon' name='Lokasyon' />
                    <label htmlFor='Yapim Yili'>Yapım Yılı</label>
                    <input type='text' id='Yapim Yili' name='Yapim_Yili' />
                    <label htmlFor='Filtre'>Filtre</label>
                    <input
                      type='color'
                      id='Filtre'
                      name='Filtre'
                      defaultValue='#8586B4'
                    />
                    <label htmlFor='Status'>Proje Durumu</label>
                    <select defaultValue='0' name='Status'>
                      <option value='0' disabled>
                        Lütfen Seçiniz
                      </option>
                      <option value='Tamamlanan'>Tamamlanan</option>
                      <option value='Devam Eden'>Devam Eden</option>
                      <option value='Planlanan'>Planlanan</option>
                    </select>
                    {this.state.Status === 'Planlanan' && (
                      <>
                        <label htmlFor='Harita'>Harita Bilgisi</label>
                        <p>
                          <i>
                            Harita bilgisini boş bırakmanız halinde detayda
                            şirketin merkez lokasyonu görüntülenecektir.
                          </i>
                        </p>
                        <input type='text' id='Harita' name='Harita' />
                      </>
                    )}
                    <label htmlFor='Background'>Arka Plan</label>
                    <input
                      type='file'
                      id='avatar'
                      name='Background'
                      accept='image/png, image/jpeg, image/jpg'
                    />
                    <label htmlFor='Fotograflar'>Fotoğraf Ekle</label>
                    <input
                      type='file'
                      id='fotograflar'
                      name='Fotograflar'
                      accept='image/png, image/jpeg, image/jpg'
                      multiple
                    />
                    {this.state.errorForm && this.state.errorForm}
                    <div className='mrg-t1 button-container'>
                      <PrimaryButton
                        onClick={(e) => this.handleSavePost(e, 'save')}
                        content='Kaydet'
                      />
                      <SecondaryButton
                        onClick={this.handleCancel}
                        content='Vazgeç'
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
        {this.state.isSelectedProject && (
          <div className='popup'>
            <div className='popupInner'>
              {this.props.update_project_loading ||
              this.props.delete_project_loading ||
              (this.state.progress_coklu !== null &&
                this.state.progress_coklu !== 100) ||
              (this.state.progress !== null && this.state.progress !== 100) ? (
                <div className='popup-alert-container'>
                  <Loading />
                </div>
              ) : this.props.update_project_fail ||
                this.props.delete_project_fail ? (
                <div className='popup-alert-container'>
                  <div className='error-alert'>
                    Bir hata oluştu lütfen tekrar deneyiniz.
                  </div>
                </div>
              ) : this.props.update_project_success ||
                this.props.delete_project_success ? (
                <div className='popup-alert-container'>
                  <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size='4x'
                      color='#155725'
                    />
                  </div>
                  <div className='success-alert'>
                    İşleminiz başarılı bir şekilde gerçekleştirildi.
                  </div>
                  <SecondaryButton
                    onClick={this.handleRefreshPage}
                    content='Tamam'
                  />
                </div>
              ) : (
                <form onChange={this.handleChange}>
                  <div className='popup-alert-container'>
                    <label htmlFor='Proje Adi'>Proje Adı</label>
                    <input
                      type='text'
                      id='Proje Adi'
                      name='Proje_Adi'
                      defaultValue={this.state.Proje_Adi}
                    />
                    <label htmlFor='Proje Adi ENG'>Proje Adı (ENG)</label>
                    <input
                      type='text'
                      id='Proje Adi ENG'
                      name='Proje_Adi_EN'
                      defaultValue={this.state.Proje_Adi_EN}
                    />
                    <label htmlFor='Proje Ozet'>Proje Özet</label>
                    <input
                      type='text'
                      id='Proje Ozet'
                      name='Proje_Ozet'
                      defaultValue={this.state.Proje_Ozet}
                    />
                    <label htmlFor='Proje Ozet ENG'>Proje Özet (ENG)</label>
                    <input
                      type='text'
                      id='Proje Ozet ENG'
                      name='Proje_Ozet_EN'
                      defaultValue={this.state.Proje_Ozet_EN}
                    />
                    <label htmlFor='Proje Aciklama'>Proje Açıklama</label>
                    <textarea
                      type='text'
                      id='Proje Aciklama'
                      name='Proje_Aciklama'
                      defaultValue={this.state.Proje_Aciklama}
                    />
                    <label htmlFor='Proje Aciklama ENG'>
                      Proje Açıklama (ENG)
                    </label>
                    <textarea
                      type='text'
                      id='Proje Aciklama ENG'
                      name='Proje_Aciklama_EN'
                      defaultValue={this.state.Proje_Aciklama_EN}
                    />
                    <label htmlFor='Adres'>Proje Adresi</label>
                    <input
                      type='text'
                      id='Adres'
                      name='Adres'
                      defaultValue={this.state.Adres}
                    />
                    <label htmlFor='Lokasyon'>Proje Lokasyonu</label>
                    <input
                      type='text'
                      id='Lokasyon'
                      name='Lokasyon'
                      defaultValue={this.state.Lokasyon}
                    />
                    <label htmlFor='Yapim Yili'>Yapım Yılı</label>
                    <input
                      type='text'
                      id='Yapim Yili'
                      name='Yapim_Yili'
                      defaultValue={this.state.Yapim_Yili}
                    />
                    <label htmlFor='Filtre'>Filtre</label>
                    <input
                      type='color'
                      id='Filtre'
                      name='Filtre'
                      defaultValue={this.state.Filtre}
                    />
                    <label htmlFor='Status'>Proje Durumu</label>
                    <select name='Status' defaultValue={this.state.Status}>
                      <option value='0' disabled>
                        Lütfen Seçiniz
                      </option>
                      <option value='Tamamlanan'>Tamamlanan</option>
                      <option value='Devam Eden'>Devam Eden</option>
                      <option value='Planlanan'>Planlanan</option>
                    </select>
                    {this.state.Status === 'Planlanan' && (
                      <>
                        <label htmlFor='Harita'>Harita Bilgisi</label>
                        <input
                          type='text'
                          id='Harita'
                          name='Harita'
                          defaultValue={this.state.Harita}
                        />
                      </>
                    )}
                    <label htmlFor='Status'>Arka Plan</label>
                    <input
                      type='file'
                      id='avatar'
                      name='Background'
                      accept='image/png, image/jpeg, image/jpg'
                    />
                    <label htmlFor='Fotograflar'>Fotoğraf Ekle</label>
                    <input
                      type='file'
                      id='fotograflar'
                      name='Fotograflar'
                      accept='image/png, image/jpeg, image/jpg'
                      multiple
                    />
                    <p className='detail-title'>Mevcut Fotoğraflar</p>
                    <div className='detailed-image-container'>
                      {this.props.images_list &&
                      this.props.images_list.length > 0 ? (
                        this.props.images_list.map((image, index) => (
                          <div className='an-image-container' key={index}>
                            <img
                              src={baseImgUrl + image.fullPath}
                              alt={index}
                              className='detailed-images'
                              name={`${index}_1`}
                              id={image.fullPath}
                            />
                            {this.state.deletedImageArray.find(
                              (i) => i === image.fullPath
                            ) ? (
                              <p>Silindi</p>
                            ) : (
                              <SecondaryButton
                                onClick={(e) =>
                                  this.handleImageDelete(e, image.fullPath)
                                }
                                content='Sil'
                              />
                            )}
                          </div>
                        ))
                      ) : (
                        <p>
                          <i>
                            Projenizin detayında herhangi bir görsel
                            bulunmamaktadır. Yukarıda bulunan fotoğraf ekleme
                            bölümünü kullanarak güncelleme yapabilirsiniz.
                          </i>
                        </p>
                      )}
                    </div>
                    {this.state.errorForm && this.state.errorForm}
                    <div className='mrg-t1 button-container'>
                      <PrimaryButton
                        onClick={(e) => this.handleSavePost(e, 'update')}
                        content='Güncelle'
                      />
                      <SecondaryButton
                        onClick={this.handleCancel}
                        content='Vazgeç'
                      />
                      <SecondaryButton
                        onClick={this.handleDelete}
                        content='Projeyi Sil'
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
        {this.state.createShow && (
          <div className='popup'>
            <div className='popupInner'>
              {this.props.isLoading_create || this.props.send_mail_loading ? (
                <div className='popup-alert-container'>
                  <Loading />
                </div>
              ) : this.props.fail_create && !this.props.send_mail_fail ? (
                <div className='popup-alert-container'>
                  <div className='error-alert'>
                    Bir hata oluştu lütfen tekrar deneyiniz.
                  </div>
                </div>
              ) : this.props.send_mail_fail ? (
                <div className='popup-alert-container'>
                  <div className='error-alert'>
                    <p>
                      Mail gönderirken bir hata oluştu. Lütfen kullanıcı adı ve
                      şifreyi manuel olarak gönderiniz.
                    </p>
                    <p>Kullanıcı adı: {this.state.Email}</p>
                    <p>Şifre: {this.state.Sifre}</p>
                  </div>
                  <SecondaryButton
                    onClick={this.handleRefreshPage}
                    content='Tamam'
                  />
                </div>
              ) : this.props.success_create && !this.props.send_mail_success ? (
                <div className='popup-alert-container'>
                  <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size='4x'
                      color='#155725'
                    />
                  </div>
                  <div className='success-alert'>
                    <p>Yeni kullanıcı başarılı bir şekilde oluşturuldu.</p>
                    <p>Kullanıcı adı: {this.state.Email}</p>
                    <p>Şifre: {this.state.Sifre}</p>
                  </div>
                  <SecondaryButton
                    onClick={this.handleSendMail}
                    content='Mail gönder'
                  />
                </div>
              ) : this.props.send_mail_success ? (
                <div className='popup-alert-container'>
                  <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size='4x'
                      color='#155725'
                    />
                  </div>
                  <div className='success-alert'>
                    {this.state.Email} kullanıcısına maliniz başarılı bir
                    şekilde gönderilmiştir.
                  </div>
                  <SecondaryButton
                    onClick={this.handleRefreshPage}
                    content='Tamam'
                  />
                </div>
              ) : (
                <form onChange={this.handleChange}>
                  <div className='popup-alert-container'>
                    <label htmlFor='Email'>Email</label>
                    <input type='text' id='Email' name='Email' />
                    <label htmlFor='Sifre'>Şifre</label>
                    <input type='text' id='Sifre' name='Sifre' />
                    {this.state.errorForm}
                    <div className='mrg-t1 button-container'>
                      <PrimaryButton
                        onClick={(e) => this.handleCreateUser(e)}
                        content='Kaydet'
                      />
                      <SecondaryButton
                        onClick={this.handleCancel}
                        content='Vazgeç'
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
        <Sidebar />
        <div className='mainPrivate'>
          {/* <NavbarLogged /> */}
          <div className='content'>
            <div className='container-fluid'>
              <div className='header'>
                <h1 className='header-title'>Admin Dashboard</h1>
                <p className='header-subtitle'>
                  Site yönetiminizi buradan gerçekleştirebilirsiniz.
                </p>
              </div>
              <div className='row-content'>
                <div className='sub-content'>
                  <PrimaryButton
                    onClick={this.handleNewPost}
                    content='Yeni Proje Ekle'
                  />
                  <PrimaryButton
                    onClick={this.showCreateUser}
                    content='Yeni Kullanıcı Oluştur'
                  />
                  <div className='projects-content-filter'>
                    <input
                      type='text'
                      name='filterSearch'
                      className='filter-search-input'
                      onChange={this.handleChange}
                      placeholder='Proje ara'
                    />
                  </div>
                  <div className='request-table-container'>
                    {this.props.get_project_loading ? (
                      <Loading />
                    ) : this.props.get_project_fail ? (
                      <div className='error-aler'>
                        Liste yüklenirken bir hata oluştu. Tekrar deneyiniz.
                      </div>
                    ) : this.props.get_project_list ? (
                      <>
                        <table className='request-table-preview'>
                          <thead>
                            <tr>
                              <th>Proje Adı</th>
                              <th>Proje Durumu</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.get_project_list
                              .filter(
                                (doc) => doc.data().Status === 'Tamamlanan'
                              )
                              .filter((doc) => {
                                if (this.state.filterSearch == null) return doc;
                                else if (
                                  doc
                                    .data()
                                    ['Proje Adi'].toLowerCase()
                                    .includes(
                                      this.state.filterSearch.toLowerCase()
                                    )
                                ) {
                                  return doc;
                                }
                              })
                              .sort(function (a, b) {
                                return a.data().sira - b.data().sira;
                              })
                              .map((doc, index) => (
                                <tr
                                  key={index}
                                  onClick={(e) =>
                                    this.handleProjectDetail(e, doc.id)
                                  }
                                >
                                  <td title={doc.data()['Proje Adi']}>
                                    {doc.data()['Proje Adi']}
                                  </td>
                                  <td title={doc.id}>{doc.data().Status}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <table className='request-table-preview'>
                          <thead>
                            <tr>
                              <th>Proje Adı</th>
                              <th>Proje Durumu</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.get_project_list
                              .filter(
                                (doc) => doc.data().Status === 'Devam Eden'
                              )
                              .filter((doc) => {
                                if (this.state.filterSearch == null) return doc;
                                else if (
                                  doc
                                    .data()
                                    ['Proje Adi'].toLowerCase()
                                    .includes(
                                      this.state.filterSearch.toLowerCase()
                                    )
                                ) {
                                  return doc;
                                }
                              })
                              .map((doc, index) => (
                                <tr
                                  key={index}
                                  onClick={(e) =>
                                    this.handleProjectDetail(e, doc.id)
                                  }
                                >
                                  <td title={doc.data()['Proje Adi']}>
                                    {doc.data()['Proje Adi']}
                                  </td>
                                  <td title={doc.id}>{doc.data().Status}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <table className='request-table-preview'>
                          <thead>
                            <tr>
                              <th>Proje Adı</th>
                              <th>Proje Durumu</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.get_project_list
                              .filter(
                                (doc) => doc.data().Status === 'Planlanan'
                              )
                              .filter((doc) => {
                                if (this.state.filterSearch == null) return doc;
                                else if (
                                  doc
                                    .data()
                                    ['Proje Adi'].toLowerCase()
                                    .includes(
                                      this.state.filterSearch.toLowerCase()
                                    )
                                ) {
                                  return doc;
                                }
                              })
                              .map((doc, index) => (
                                <tr
                                  key={index}
                                  onClick={(e) =>
                                    this.handleProjectDetail(e, doc.id)
                                  }
                                >
                                  <td title={doc.data()['Proje Adi']}>
                                    {doc.data()['Proje Adi']}
                                  </td>
                                  <td title={doc.id}>{doc.data().Status}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  post_new_project_loading: state.projeler.post_new_project_loading,
  post_new_project_success: state.projeler.post_new_project_success,
  post_new_project_fail: state.projeler.post_new_project_fail,
  get_project_loading: state.projeler.get_project_loading,
  get_project_list: state.projeler.get_project_list,
  get_project_fail: state.projeler.get_project_fail,
  update_project_loading: state.projeler.update_project_loading,
  update_project_success: state.projeler.update_project_success,
  update_project_fail: state.projeler.update_project_fail,
  delete_project_loading: state.projeler.delete_project_loading,
  delete_project_success: state.projeler.delete_project_success,
  delete_project_fail: state.projeler.delete_project_fail,
  isLoading_create: state.auth.isLoading_create,
  success_create: state.auth.success_create,
  fail_create: state.auth.fail_create,
  send_mail_loading: state.mail.send_mail_loading,
  send_mail_success: state.mail.send_mail_success,
  send_mail_fail: state.mail.send_mail_fail,
  images_list: state.projeler.images_list,
});

export default connect(mapStateToProps, {
  postNewProject,
  getProjectList,
  updateProject,
  deleteProject,
  createUser,
  Email,
  getProjectImages,
})(Dashboard);
