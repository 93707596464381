import React from 'react';
import { Helmet } from 'react-helmet'

import { withNamespaces } from 'react-i18next';

import './Hizmetlerimiz.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

function Hizmetlerimiz({ t }) {
    return (
        <div className="fluid top-border">
            <Helmet>
                <title>{t('Title_Hizmetlerimiz')}</title>
            </Helmet>
            <div className="fluid-container">
                <div className="box-container">
                    <div className="flip-card">
                        <div className="bg-produce bg-blurred"></div>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <div className="box-content">
                                    <h1>{t('Insaat')}</h1>
                                    <p>
                                        <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                        <span className="mrg-l05">{t('Devami')}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="flip-card-back">
                                <div className="box-content">
                                    <h1>{t('Insaat')}</h1>
                                    <div className="flip-back-content">
                                        <div className="flip-back-left">
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Insaat_Aciklama_1')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Insaat_Aciklama_2')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Insaat_Aciklama_3')}</span>
                                            </p>
                                        </div>
                                        <div className="flip-back-right">
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Insaat_Aciklama_4')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Insaat_Aciklama_5')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Insaat_Aciklama_6')}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="bg-develop bg-blurred"></div>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <div className="box-content">
                                    <h1>{t('Proje_Yonetimi')}</h1>
                                    <p>
                                        <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                        <span className="mrg-l05">{t('Devami')}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="flip-card-back">
                                <div className="box-content">
                                    <h1>{t('Proje_Yonetimi')}</h1>
                                    <div className="flip-back-content">
                                        <div className="flip-back-left">
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Proje_Yonetimi_Aciklama_1')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Proje_Yonetimi_Aciklama_2')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Proje_Yonetimi_Aciklama_3')}</span>
                                            </p>
                                        </div>
                                        <div className="flip-back-right">
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Proje_Yonetimi_Aciklama_4')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Proje_Yonetimi_Aciklama_5')}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box-container">
                    <div className="flip-card">
                        <div className="bg-search bg-blurred"></div>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <div className="box-content">
                                    <h1>{t('Yatirim')}</h1>
                                    <p>
                                        <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                        <span className="mrg-l05">{t('Devami')}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="flip-card-back">
                                <div className="box-content">
                                    <h1>{t('Yatirim')}</h1>
                                    <div className="flip-back-content">
                                        <div className="flip-back-left">
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Yatirim_Aciklama_1')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Yatirim_Aciklama_2')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Yatirim_Aciklama_3')}</span>
                                            </p>
                                        </div>
                                        <div className="flip-back-right">
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Yatirim_Aciklama_4')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Yatirim_Aciklama_5')}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="bg-manage bg-blurred"></div>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <div className="box-content">
                                    <h1>{t('Is_Gelistirme')}</h1>
                                    <p>
                                        <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                        <span className="mrg-l05">{t('Devami')}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="flip-card-back">
                                <div className="box-content">
                                    <h1>{t('Is_Gelistirme')}</h1>
                                    <div className="flip-back-content">
                                        <div className="flip-back-left">
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Is_Gelistirme_Aciklama_1')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Is_Gelistirme_Aciklama_2')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Is_Gelistirme_Aciklama_3')}</span>
                                            </p>
                                        </div>
                                        <div className="flip-back-right">
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Is_Gelistirme_Aciklama_4')}</span>
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                                                <span className="mrg-l05">{t('Is_Gelistirme_Aciklama_5')}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withNamespaces()(Hizmetlerimiz)
