import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { withNamespaces } from 'react-i18next';

import { authLogin, currentUser } from '../../Actions/Auth'

import './Login.css';

import logo from '../../res/acrelogo.svg'
import { Link, Redirect } from 'react-router-dom';
import Loading from '../Loading/Loading';

export class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: null,
            password: null
        }
    }

    handleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        this.setState(prevState => ({
            ...prevState,
            [name]: e.target.value
        }))
    }

    handleLogin = (e) => {
        e.preventDefault()
        if (this.state.email && this.state.password) {
            this.props.authLogin(this.state.email, this.state.password)
            this.setState({
                email: null,
                password: null
            })
        }
        if (!this.state.email || !this.state.password) {
            this.setState({
                errorInfo: <div className="error-alert unhidden">{this.props.t('Tum_Alanlari_Doldur')}</div>
            })
        }

    }
    render() {
        const { t } = this.props
        return (
            <>
                <Helmet>
                    <title>{t('Title_Giris')}</title>
                </Helmet>
                {this.props.isLoading_auth ?
                    <Loading />
                    :
                    this.props.isAuthenticated ?
                        <Redirect to={{
                            pathname: "/dashboard",
                            state: { from: this.props.location }
                        }} />
                        :
                        <div className="login-container">
                            <div className="login-content">
                                <div className="main-background"></div>
                                <div className="login-image">
                                    <Link to="/">
                                        <img src={logo} alt="Logo" width="200px" />
                                    </Link>
                                </div>
                                <div className="login-inputs">
                                    <div className={this.props.fail_auth ? "error-alert unhidden" : "error-alert"}>
                                        {t('Hatali_Kullanici')}
                                    </div>
                                    {!this.props.fail_auth && this.state.errorInfo}
                                    <form onChange={this.handleChange} onSubmit={this.handleLogin} className="login-inputs">
                                        <input type="email" name="email" placeholder={t('Kullanici_Adi')} />
                                        <input type="password" name="password" placeholder={t('Sifre')} />
                                        <button type="submit">{t('Giris_Yap')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading_auth: state.auth.isLoading_auth,
    success_auth: state.auth.success_auth,
    fail_auth: state.auth.fail_auth,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { authLogin, currentUser })(withNamespaces()(Login))

