import {
    SEND_MAIL_LOADING,
    SEND_MAIL_SUCCESS,
    SEND_MAIL_FAIL
} from '../Actions/Types'

const initialState = {
    send_mail_loading: false,
    send_mail_success: null,
    send_mail_fail: null,
}

export default function mail(state = initialState, action) {
    switch (action.type) {
        case SEND_MAIL_LOADING:
            return {
                ...state,
                send_mail_loading: true
            }
        case SEND_MAIL_SUCCESS:
            return {
                ...state,
                send_mail_loading: false,
                send_mail_success: action.payload,
                send_mail_fail: null
            }
        case SEND_MAIL_FAIL:
            return {
                ...state,
                send_mail_loading: false,
                send_mail_success: null,
                send_mail_fail: action.payload
            }
        default:
            return state;
    }
} 