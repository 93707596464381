import React from 'react';
import { Link } from 'react-router-dom';

import './NotFound404.css';

function NotFound() {
    return (
        <div className="not-found-404-main">
            <div className="not-found-404-text-container">
                <h1>404 | Bulunamadı</h1>
                <h3>Üzgünüz, <i>{window.location.pathname.substring(1)}</i> sayfası henüz mevcut değil.</h3>
                <div className="marketplace-btn-back">
                    <Link to="/anasayfa" className="btn-primary mrg-t1">Anasayfa</Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound
