import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { withNamespaces } from 'react-i18next';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Email } from '../../Actions/Mail'

import { getProjectList, getProjectImages } from '../../Actions/Projeler'

import './ProjeDetay.css';


import Loading from '../Loading/Loading'
import { baseImgUrl } from '../../constants'
import RequestForm from './RequestForm';


export class ProjeDetay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            photos: null,
            fullSize: null,
            errorForm: ""
        }
    }

    componentDidMount() {
        if (this.props.success_ip && this.props.success_ip["country_name"] !== "Turkey" || this.props.isAuthenticated) {
            this.props.getProjectList()
            this.props.getProjectImages(this.props.match.params.proje)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.images_list !== prevProps.images_list) {
            const photos = []
            for (let i = 0; i < this.props.images_list.length; i++) {
                photos.push(
                    {
                        src: baseImgUrl + this.props.images_list[i].fullPath,
                        src: baseImgUrl + this.props.images_list[i],
                        width: 1,
                        height: 1
                    }
                )
            }
            this.setState({
                photos: photos
            })
        }
    }

    fullSize = (e) => {
        e.preventDefault()
        const name = e.target.name
        const key = e.target.id
        this.setState({
            fullSize: name,
            photoKey: key
        })
    }

    miniSize = (e) => {
        e.preventDefault()
        this.setState({
            fullSize: null
        })
    }

    handleRequest = (message, reCaptcha) => {
        if (!message.Adiniz || !message.Email || !message.SirketAdi || !message.Talep) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Tum_Alanlari_Doldur')}</div>
            })
        } else if (!message.Email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Gecerli_Mail')}</div>
            })
        } else if (!reCaptcha) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Recaptcha_Dogrula')}</div>
            })
        } else {
            this.props.Email(message)
            this.setState({
                errorForm: null
            })
        }
    }

    handleSonraki = (e) => {
        e.preventDefault()
        this.setState({
            fullSize: `${parseInt(this.state.photoKey) + 1}_1`,
            photoKey: parseInt(this.state.photoKey) + 1
        })
    }

    handleOnceki = (e) => {
        e.preventDefault()
        this.setState({
            fullSize: `${parseInt(this.state.photoKey) - 1}_1`,
            photoKey: parseInt(this.state.photoKey) - 1
        })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Helmet>
                    <title>
                        {(this.props.get_project_list && this.props.get_project_list.filter(doc => doc.id === this.props.match.params.proje).length > 0) ?
                            `${this.props.get_project_list.filter(doc => doc.id === this.props.match.params.proje)[0].data()["Proje Adi"]} | ACRE Group`
                            :
                            "ACRE Group"
                        }
                    </title>
                </Helmet>
                <div className="tabs">
                    <Link to="/projeler">{t('Geri')}</Link>
                    <button
                        className="active-tab">{
                            window.location.pathname[10] === "t" ? t('Tamamlanan')
                                : window.location.pathname[10] === "d" ? t('Devam_Eden')
                                    : t('Planlanan')
                        }
                    </button>
                    <button></button>
                </div>
                {(this.props.success_ip && this.props.success_ip["country_name"] === "Turkey" && !this.props.isAuthenticated) ?
                    <RequestForm
                        handleRequest={(message, reCaptcha) => this.handleRequest(message, reCaptcha)}
                        errorForm={this.state.errorForm} />
                    : (this.props.fail_ip && !this.props.isAuthenticated) ?
                        <RequestForm
                            handleRequest={(message, reCaptcha) => this.handleRequest(message, reCaptcha)}
                            errorForm={this.state.errorForm} />
                        :
                        this.props.get_project_loading ?
                            <Loading />
                            : (this.props.get_project_list && this.props.get_project_list.filter(doc => doc.id === this.props.match.params.proje).length <= 0) ?
                                <div className="fluid project-details-container">
                                    <div className="details-content">
                                        <b>{t('Proje_Silinmis')}</b>
                                    </div>
                                </div>
                                : this.props.get_project_list && this.props.get_project_list.filter(doc => doc.id === this.props.match.params.proje).map((doc, index) =>
                                    <div className="fluid project-details-container" key={index}>
                                        <div className="details-content">
                                            <h1 className="project-detail-title">
                                                {this.props.selectedLang === "tr" ? doc.data()["Proje Adi"] : doc.data()["EN_Adi"]}
                                            </h1>
                                            <h4>{t('Adres')} : <span>{doc.data().Adres}</span></h4>
                                            <h4>{t('Yapim_Yili')} : <span>{doc.data()["Yapim Yili"]}</span></h4>
                                            <p className="project-details-explanation">
                                                {this.props.selectedLang === "tr" ? doc.data()["Proje Aciklama"] : doc.data()["EN_Aciklama"]}
                                            </p>
                                        </div>
                                        <>
                                            {doc.data().Status === "Planlanan" &&
                                                <LocationApp iframeSRC={doc.data().Harita} />
                                            }
                                        </>
                                        <div className="project-details-photo-container">
                                            {this.props.images_loading ?
                                                <Loading />
                                                :
                                                (this.props.images_list) && (this.props.images_list.length > 0) ?
                                                    this.props.images_list.map((image, index) =>
                                                        <div className="proje-detay-foto" key={index}>
                                                            <img src={baseImgUrl + image.fullPath}
                                                                alt={doc.data()["Proje Adi"] + doc.data().Adres + doc.data()["Yapim Yili"] + index}
                                                                className="zoom-in"
                                                                name={`${index}_1`}
                                                                id={index}
                                                                onClick={this.fullSize}
                                                            />
                                                            {
                                                                this.state.fullSize === `${index}_1` &&
                                                                <div className="fl-bg">
                                                                    <button className="close-btn" onClick={this.miniSize}>{t('Kapat')}</button>
                                                                    <button
                                                                        disabled={this.state.photoKey != 0 ? false : true}
                                                                        className={this.state.photoKey != 0 ? "detay-onceki-foto" : "onceki-disabled"}
                                                                        onClick={this.state.photoKey != 0 ? this.handleOnceki : null}>{t('Onceki')}</button>
                                                                    <img src={baseImgUrl + image.fullPath}
                                                                        alt={doc.data()["Proje Adi"] + doc.data().Adres + doc.data()["Yapim Yili"] + index}
                                                                        className="full-size"
                                                                        name={`${index}_1`}
                                                                        onClick={this.miniSize}
                                                                    />
                                                                    <button className="detay-sonraki-foto" onClick={this.handleSonraki}>{t('Sonraki')}</button>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    get_project_loading: state.projeler.get_project_loading,
    get_project_list: state.projeler.get_project_list,
    get_project_fail: state.projeler.get_project_fail,
    images_loading: state.projeler.images_loading,
    images_list: state.projeler.images_list,
    images_fail: state.projeler.images_fail,
    success_ip: state.auth.success_ip,
    fail_ip: state.auth.fail_ip,
    isAuthenticated: state.auth.isAuthenticated,
    selectedLang: state.auth.selectedLang
})

export default connect(mapStateToProps, { getProjectList, getProjectImages, Email })(withNamespaces()(ProjeDetay))


const LocationApp = (props) => {
    return (
        <iframe src={props.iframeSRC} width="100%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
    )
}

LocationApp.defaultProps = {
    iframeSRC: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12073.943524884773!2d29.43715!3d40.839255!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x84c8ab817bc59a3c!2sGebze%20G%C3%BCzeller%20Organize%20Sanayi%20B%C3%B6lgesi!5e0!3m2!1str!2str!4v1607427463548!5m2!1str!2str"
};