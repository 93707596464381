import React from 'react';
import './LoginButton.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';

function LoginButton({ t }) {
    const auth = useSelector(state => state.auth);
    return (
        // <div className="giris-yap-btn">
        <>
            {auth.isAuthenticated ?
                <Link to="/logout" className="giris-yap-btn">{t('Cikis')}</Link>
                :
                <Link to="/login" className="giris-yap-btn">{t("Giris")}</Link>
            }
        </>

    )
}

export default withNamespaces()(LoginButton)
