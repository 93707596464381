import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { withNamespaces } from 'react-i18next';

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Email } from '../../Actions/Mail';
import { getProjectList, handleScrollPos, stateSelectedTab } from '../../Actions/Projeler';
import { baseImgUrl } from '../../constants';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import './Projeler.css';
import RequestForm from './RequestForm';

export class Projeler extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errorForm: ""
        }
    }

    componentDidMount() {
        if (this.props.success_ip?.data && this.props.success_ip?.data?.location?.country?.name !== "Turkey" || this.props.isAuthenticated) {
            this.props.getProjectList()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.get_project_list !== prevProps.get_project_list) {
            setTimeout(() => {
                window.scrollTo({
                    top: this.props.scrollPos,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        }
    }

    handleTab = (e, tab) => {
        e.preventDefault()
        // const value = e.target.value
        // this.setState({
        //     selectedTab: value
        // })
        this.props.stateSelectedTab(tab)
    }

    handlesScroll = (e) => {
        e.preventDefault()
        this.props.handleScrollPos(window.pageYOffset)
    }

    handleRequest = (message, reCaptcha) => {
        if (!message.Adiniz || !message.Email || !message.SirketAdi || !message.Talep) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Tum_Alanlari_Doldur')}</div>
            })
        } else if (!message.Email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Gecerli_Mail')}</div>
            })
        } else if (!reCaptcha) {
            this.setState({
                errorForm: <div className="request-reason mrg-t1">{this.props.t('Recaptcha_Dogrula')}</div>
            })
        } else {
            this.props.Email(message)
            this.setState({
                errorForm: null
            })
        }
    }


    render() {
        const { t } = this.props
        return (
            <div>
                <Helmet>
                    <title>{t('Title_Projeler')}</title>
                </Helmet>
                <div>
                    <div className="tabs">
                        <button
                            value="Tamamlanan"
                            onClick={e => this.handleTab(e, "Tamamlanan")}
                            className={this.props.selectedTab === "Tamamlanan" ? "active-tab" : null}>{t('Tamamlanan')}</button>
                        <button
                            value="Devam Eden"
                            onClick={e => this.handleTab(e, "Devam Eden")}
                            className={this.props.selectedTab === "Devam Eden" ? "active-tab" : null}>{t('Devam_Eden')}</button>
                        <button
                            value="Planlanan"
                            onClick={e => this.handleTab(e, "Planlanan")}
                            className={this.props.selectedTab === "Planlanan" ? "active-tab" : null}>{t('Planlanan')}</button>
                    </div>
                    <div className="project-list">
                        <div className="projects">
                            <div className="project-container">
                                {(this.props.success_ip?.data?.location?.country?.name === "Turkey" && !this.props.isAuthenticated) ?
                                    <RequestForm
                                        handleRequest={(message, reCaptcha) => this.handleRequest(message, reCaptcha)}
                                        errorForm={this.state.errorForm} />
                                    : (this.props.fail_ip && !this.props.isAuthenticated) ?
                                        <RequestForm
                                            handleRequest={(message, reCaptcha) => this.handleRequest(message, reCaptcha)}
                                            errorForm={this.state.errorForm} />
                                        :
                                        this.props.get_project_loading ?
                                            <Loading />
                                            : this.props.get_project_fail ?
                                                <div className="error-alert unhidden">Hata ile karşılaşıldı. Lütfen tekrar deneyin.</div>
                                                :
                                                this.props.selectedTab === "Tamamlanan" ?
                                                    this.props.get_project_list && this.props.get_project_list.filter(doc => doc.data().Status === "Tamamlanan")
                                                        .sort(function (a, b) { return a.data().sira - b.data().sira }).map((doc, index) =>
                                                            <Proje
                                                                key={index}
                                                                scrollPos={this.handlesScroll}
                                                                group="tamamlanan"
                                                                id={doc.id}
                                                                filtre={doc.data().Filtre}
                                                                projeAdi={this.props.selectedLang === "tr" ? doc.data()["Proje Adi"] : doc.data()["EN_Adi"]}
                                                                projeOzet={this.props.selectedLang === "tr" ? doc.data()["Proje Ozet"] : doc.data()["EN_Ozet"]}
                                                                lokasyon={doc.data().Lokasyon}
                                                            />
                                                        )
                                                    : this.props.selectedTab === "Devam Eden" ?
                                                        this.props.get_project_list && this.props.get_project_list.filter(doc => doc.data().Status === "Devam Eden").map((doc, index) =>
                                                            <Proje
                                                                key={index}
                                                                scrollPos={this.handlesScroll}
                                                                group="devam_eden"
                                                                id={doc.id}
                                                                filtre={doc.data().Filtre}
                                                                projeAdi={this.props.selectedLang === "tr" ? doc.data()["Proje Adi"] : doc.data()["EN_Adi"]}
                                                                projeOzet={this.props.selectedLang === "tr" ? doc.data()["Proje Ozet"] : doc.data()["EN_Ozet"]}
                                                                lokasyon={doc.data().Lokasyon}
                                                            />
                                                        )
                                                        : this.props.selectedTab === "Planlanan" ?
                                                            this.props.get_project_list && this.props.get_project_list.filter(doc => doc.data().Status === "Planlanan").map((doc, index) =>
                                                                <Proje
                                                                    key={index}
                                                                    scrollPos={this.handlesScroll}
                                                                    group="planlanan"
                                                                    id={doc.id}
                                                                    filtre={doc.data().Filtre}
                                                                    projeAdi={this.props.selectedLang === "tr" ? doc.data()["Proje Adi"] : doc.data()["EN_Adi"]}
                                                                    projeOzet={this.props.selectedLang === "tr" ? doc.data()["Proje Ozet"] : doc.data()["EN_Ozet"]}
                                                                    lokasyon={doc.data().Lokasyon}
                                                                />
                                                            )
                                                            : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    get_project_loading: state.projeler.get_project_loading,
    get_project_list: state.projeler.get_project_list,
    get_project_fail: state.projeler.get_project_fail,
    success_ip: state.auth.success_ip,
    fail_ip: state.auth.fail_ip,
    isAuthenticated: state.auth.isAuthenticated,
    selectedTab: state.projeler.selectedTab,
    scrollPos: state.projeler.scrollPos,
    selectedLang: state.auth.selectedLang
})

export default connect(mapStateToProps, { getProjectList, Email, stateSelectedTab, handleScrollPos })(withNamespaces()(Projeler))

function Proje(props) {
    const { id, filtre, projeAdi, projeOzet, lokasyon, group, scrollPos } = props
    return (
        <div className="project"
            onClick={scrollPos}
            style={{ backgroundImage: `url(${baseImgUrl}Projects/Background/${id}.jpg)` }}>
            <div className="filter-part" style={{ backgroundColor: filtre }}></div>
            <Link to={`/projeler/${group}/${id}`}>
                <div className="project-content">
                    <div className="project-title">{projeAdi}</div>
                    <div className="project-divider"></div>
                    <p className="project-description">{projeOzet}</p>
                    <p className="project-location">
                        <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                        <span>{lokasyon}</span>
                    </p>
                </div>
            </Link>
        </div>
    )
}