import {
    AUTH_LOADING,
    AUTH_SUCCESS,
    AUTH_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_LOADING,
    GET_IP_LOADING,
    GET_IP_SUCCESS,
    GET_IP_FAIL,
    CREATE_USER_LOADING,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    SELECTED_LANG
} from '../Actions/Types';

const initialState = {
    isLoading_auth: false,
    success_auth: null,
    fail_auth: null,
    pendingLoad: false,
    isAuthenticated: false,
    isLoading_logout: false,
    isLoading_ip: true,
    success_ip: null,
    fail_ip: null,
    isLoading_create: false,
    success_create: null,
    fail_create: null,
    selectedLang: "tr"
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case AUTH_LOADING:
            return {
                ...state,
                isLoading_auth: true,
            }
        case AUTH_SUCCESS:
            return {
                ...state,
                isLoading_auth: false,
                success_auth: action.payload,
                fail_auth: null,
                isAuthenticated: true,
                pendingLoad: true
            }
        case AUTH_FAIL:
            return {
                ...state,
                isLoading_auth: false,
                success_auth: null,
                fail_auth: action.payload,
            }
        case LOGOUT_LOADING:
            return {
                ...state,
                isLoading_logout: true
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                isLoading_logout: false
            }
        case GET_IP_LOADING:
            return {
                ...state,
                isLoading_ip: true
            }
        case GET_IP_SUCCESS:
            return {
                ...state,
                isLoading_ip: false,
                success_ip: action.payload,
                fail_ip: null
            }
        case GET_IP_FAIL:
            return {
                ...state,
                isLoading_ip: false,
                success_ip: null,
                fail_ip: action.payload
            }
        case CREATE_USER_LOADING:
            return {
                ...state,
                isLoading_create: true
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                isLoading_create: false,
                success_create: action.payload,
                fail_create: null
            }
        case CREATE_USER_FAIL:
            return {
                ...state,
                isLoading_create: false,
                success_create: null,
                fail_create: action.payload
            }
        case SELECTED_LANG:
            return {
                ...state,
                selectedLang: action.payload,
            }
        default:
            return state;
    }
}