import auth from './Auth'
import projeler from './Projeler'
import mail from './Mail'

const reducers = {
    auth,
    projeler,
    mail
}
export default reducers
